import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy, Signal, signal } from '@angular/core';
import { UserExamSessionStatusDTO } from '@models/exam/examSession.model';
import { ExamStep, ExamStepType } from '@models/exam/examSteps.model';
import { AuthService } from '@services/auth.service';
import { BaseService } from '@services/base.service';
import { ExamSessionService } from '@services/examSession.service';
import { UserExamSessionService } from '@services/userExamSession.service';
import {
  DEFAULT_CANDIDATE_POLLING,
  DEFAULT_REDIRECTION_TIMEOUT,
  DEFAULT_WAITING_PAGE_TIMEOUT,
} from '@utils/constants';
import { CandidateRoute } from '@utils/routes';
import { MessageService } from 'primeng/api';
import {
  Subscription,
  catchError,
  delay,
  of,
  switchMap,
  throwError,
  timer,
} from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CandidateExamSessionService
  extends BaseService
  implements OnDestroy
{
  private readonly _currentStep = signal<ExamStepType | null>(null);
  private readonly _isWaitingExamScreenDisplayed = signal<boolean>(false);

  public sessionCode?: string = '';
  public subscriptionInterval: Subscription;
  public mainSubscription?: Subscription;
  private providerId: string = '';
  private redirectionTimeout: ReturnType<typeof setTimeout> | undefined;

  constructor(
    protected override http: HttpClient,
    protected override messageService: MessageService,
    protected examSessionService: ExamSessionService,
    protected userExamSessionService: UserExamSessionService,
    private readonly authService: AuthService,
  ) {
    super(http, messageService);
    this.subscriptionInterval = new Subscription();
  }

  getGlobalInformations(): void {
    const providerIdAndSessionCode =
      this.authService.getProviderIdAndSessionCode();
    this.providerId = providerIdAndSessionCode.providerId;
    this.sessionCode = providerIdAndSessionCode.sessionCode.toString();
    this.mainSubscription = this.examSessionService
      .getExamSessionByPin(this.sessionCode)
      .subscribe((examSession) => {
        const examSessionPin = examSession?.pin;
        if (examSessionPin == null) return;
        // Polling du WS
        this.subscriptionInterval = timer(0, DEFAULT_CANDIDATE_POLLING)
          .pipe(
            switchMap(() =>
              this.userExamSessionService.getUserExamSessionByPinAndProviderId(
                examSessionPin,
                this.providerId,
              ),
            ),
            tap((userExamSession: UserExamSessionStatusDTO) => {
              // On redirige vers l'écran de login pour qu'il repasse dans le candidateExamSession.guard.ts
              this.redirectUnauthorizedUsers(userExamSession);
              return userExamSession;
            }),
            catchError((err) => throwError(() => err)),
          )
          .subscribe({
            next: (userExamSession) => {
              if (userExamSession == null) return;
              // On alimente le subject qui sera lu dans le candidateExamSession.guard.ts
              this.updateExamSession(userExamSession);
            },
            error: () => {
              // On gère toutes les déconnexions ou failure serveur pour rediriger le candidat vers l'écran de login
              this.showErrorToast(
                'Une erreur inattendue est survenue. Tu vas être redirigé vers le menu de connexion',
              );
              this.redirectionTimeout = setTimeout(
                () => this.router.navigate([CandidateRoute.Login]),
                DEFAULT_REDIRECTION_TIMEOUT,
              );
            },
          });
      });
  }

  updateCurrentStep(examStep: ExamStepType | null): void {
    this._currentStep?.set(examStep);
  }

  updateIsWaitingExamScreenDisplayed(isDisplayed: boolean): void {
    this._isWaitingExamScreenDisplayed.set(isDisplayed);
  }

  updateIsWaitingExamScreenDisplayedWithDelay(
    isDisplayed: boolean,
    time: number,
  ): void {
    of(null)
      .pipe(delay(time))
      .subscribe(() => {
        this.updateIsWaitingExamScreenDisplayed(isDisplayed);
      });
  }

  getCurrentStep(): Signal<ExamStepType | null> {
    return this._currentStep.asReadonly();
  }

  isExamScreenDisplayed(): Signal<boolean> {
    return this._isWaitingExamScreenDisplayed.asReadonly();
  }

  // Redirection on user not connected OR not present while training has started
  hasTobeRedirectedToLogin(
    userExamSession?: UserExamSessionStatusDTO,
  ): boolean {
    if (userExamSession?.examSessionStatus?.deleted === true) return true;

    if (!userExamSession) return true;
    // Case 1 : User has not validated (not present before starting training)
    const userNotPresentBeforeTraining =
      (userExamSession?.present === false ||
        userExamSession?.connected === false) &&
      userExamSession?.examSessionStatus?.hasValidatedCandidatesInformation ===
        true;
    // Case 2 : Session is ended
    return (
      userNotPresentBeforeTraining ||
      (userExamSession?.examSessionStatus?.hasClickedOnSessionEnd ?? true)
    );
  }

  redirectUnauthorizedUsers(userExamSession: UserExamSessionStatusDTO): void {
    if (!this.hasTobeRedirectedToLogin(userExamSession)) {
      return;
    }

    // On n'affiche pas le toast dans le cas ou l'utilisateur est sur le step
    const isUserOnResultScreen =
      userExamSession.hasFinishedExam &&
      userExamSession.examSessionStatus?.hasClickedOnSessionEnd;

    if (userExamSession.examSessionStatus?.deleted === true) {
      this.showInfoToast(
        "L'examen à été annulé, vous allez être redirigé vers l'écran de connexion",
      );
    } else if (isUserOnResultScreen === false) {
      this.showErrorToast(
        "Vous n'êtes pas autorisé à poursuivre l'examen et allez être redirigé vers l'écran de connexion",
      );
    } else {
      this.showInfoToast(
        "Vous avez terminé l'examen et allez être redirigé vers la page de connexion",
      );
    }

    // On met un timeout de 2 secondes pour avoir le temps d'afficher le toast d'erreur avant la redirection
    this.redirectionTimeout = setTimeout(
      () => this.router.navigate([CandidateRoute.Login]),
      DEFAULT_REDIRECTION_TIMEOUT,
    );
    this.subscriptionInterval.unsubscribe();
  }

  updateExamSession(userExamSession?: UserExamSessionStatusDTO): void {
    if (userExamSession == null) return;

    if (this.isExamStepPersonalInformation(userExamSession)) {
      this.updateCurrentStep(ExamStep.PERSONAL_INFORMATION);
    } else if (this.isExamStepWaitingTraining(userExamSession)) {
      this.updateCurrentStep(ExamStep.WAITING_TRAINING);
    } else if (this.isExamStepExplanation(userExamSession)) {
      this.updateCurrentStep(ExamStep.EXPLANATION_TRAINING);
    } else if (this.isExamStepTraining(userExamSession)) {
      this.updateCurrentStep(ExamStep.TRAINING_QUESTIONS);
    } else if (this.isExamStepTrainingCorrection(userExamSession)) {
      this.updateCurrentStep(ExamStep.TRAINING_CORRECTION);
    } else if (this.isExamStepWaitingExam(userExamSession)) {
      this.updateCurrentStep(ExamStep.WAITING_EXAM);
    } else if (this.isExamStepExam(userExamSession)) {
      if (!this.isExamScreenDisplayed()()) {
        this.updateIsWaitingExamScreenDisplayedWithDelay(
          true,
          DEFAULT_WAITING_PAGE_TIMEOUT,
        );
      }

      this.updateCurrentStep(ExamStep.EXAM);
    } else if (this.isExamStepSessionEnd(userExamSession)) {
      this.updateCurrentStep(ExamStep.SESSION_END);
    } else if (this.isExamStepResult(userExamSession)) {
      this.updateCurrentStep(ExamStep.RESULTS);
    }
  }

  isExamStepPersonalInformation(
    userExamSession: UserExamSessionStatusDTO,
  ): boolean {
    return (
      userExamSession?.connected === false &&
      userExamSession?.hasFinishedTraining === false &&
      userExamSession?.hasFinishedExam === false &&
      userExamSession?.examSessionStatus?.hasClickedOnStartTraining === false &&
      userExamSession?.examSessionStatus?.hasValidatedCandidatesInformation ===
        false
    );
  }

  isExamStepWaitingTraining(
    userExamSession: UserExamSessionStatusDTO,
  ): boolean {
    return (
      userExamSession?.connected === true &&
      userExamSession?.hasFinishedTraining === false &&
      userExamSession?.hasFinishedExam === false &&
      userExamSession?.examSessionStatus?.hasClickedOnStartTraining === false
    );
  }

  isExamStepExplanation(userExamSession: UserExamSessionStatusDTO): boolean {
    return (
      userExamSession?.present === true &&
      userExamSession?.connected === true &&
      userExamSession?.hasFinishedExplanation === false &&
      userExamSession?.hasFinishedTraining === false &&
      userExamSession?.hasFinishedExam === false &&
      userExamSession?.examSessionStatus?.hasClickedOnStartTraining === true &&
      userExamSession?.examSessionStatus?.hasValidatedCandidatesInformation ===
        true
    );
  }

  isExamStepTraining(userExamSession: UserExamSessionStatusDTO): boolean {
    return (
      userExamSession?.present === true &&
      userExamSession?.connected === true &&
      userExamSession?.hasFinishedExplanation === true &&
      userExamSession?.hasFinishedTraining === false &&
      userExamSession?.hasFinishedTrainingExamples === false &&
      userExamSession?.hasFinishedExam === false &&
      userExamSession?.examSessionStatus?.hasClickedOnStartTraining === true &&
      userExamSession?.examSessionStatus?.hasValidatedCandidatesInformation ===
        true
    );
  }

  isExamStepTrainingCorrection(
    userExamSession: UserExamSessionStatusDTO,
  ): boolean {
    return (
      userExamSession?.present === true &&
      userExamSession?.connected === true &&
      userExamSession?.hasFinishedExplanation === true &&
      userExamSession?.hasFinishedTrainingExamples === true &&
      userExamSession?.hasFinishedTraining === false &&
      userExamSession?.hasFinishedExam === false &&
      userExamSession?.examSessionStatus?.hasClickedOnStartTraining === true &&
      userExamSession?.examSessionStatus?.hasValidatedCandidatesInformation ===
        true
    );
  }

  isExamStepWaitingExam(userExamSession: UserExamSessionStatusDTO): boolean {
    return (
      userExamSession?.present === true &&
      userExamSession?.connected === true &&
      userExamSession?.hasFinishedTraining === true &&
      userExamSession?.hasFinishedExam === false &&
      userExamSession?.examSessionStatus?.hasClickedOnStartExam === false &&
      userExamSession?.examSessionStatus?.hasValidatedCandidatesInformation ===
        true
    );
  }

  isExamStepExam(userExamSession: UserExamSessionStatusDTO): boolean {
    return (
      userExamSession?.present === true &&
      userExamSession?.connected === true &&
      userExamSession?.hasFinishedTraining === true &&
      userExamSession?.hasFinishedExam === false &&
      userExamSession?.examSessionStatus?.hasClickedOnStartExam === true &&
      userExamSession?.examSessionStatus?.hasValidatedCandidatesInformation ===
        true
    );
  }

  isExamStepSessionEnd(userExamSession: UserExamSessionStatusDTO): boolean {
    return (
      userExamSession?.present === true &&
      userExamSession?.connected === true &&
      userExamSession?.hasFinishedTraining === true &&
      userExamSession?.hasFinishedExam === true &&
      userExamSession?.examSessionStatus?.hasClickedOnShowResult === false &&
      userExamSession?.examSessionStatus?.hasValidatedCandidatesInformation ===
        true
    );
  }

  isExamStepResult(userExamSession: UserExamSessionStatusDTO): boolean {
    return (
      userExamSession?.present === true &&
      userExamSession?.connected === true &&
      userExamSession?.hasFinishedTraining === true &&
      userExamSession?.hasFinishedExam === true &&
      userExamSession?.examSessionStatus?.hasClickedOnShowResult === true &&
      userExamSession?.examSessionStatus?.hasValidatedCandidatesInformation ===
        true
    );
  }

  ngOnDestroy(): void {
    this.mainSubscription?.unsubscribe();
    this.subscriptionInterval.unsubscribe();
    clearTimeout(this.redirectionTimeout);
  }
}
